<template>
  <div class="login bg-primary--light p-5">
    <h5 class="">Login</h5>

    <form class="mt-lg-5" @submit.prevent="login">
      <rm-input
        type="email"
        v-model="form.username"
        label="Email or phone number"
        @blur="validateInputType"
        @keypress="errorMsg = ''"
      >
        <div class="error">
          {{ errorMsg }}
        </div>
      </rm-input>

      <rm-input
        type="password"
        v-model="form.password"
        label="Password"
        :class="{ error: $v.form.password.$error }"
        @blur="$v.form.password.$touch()"
      >
        <!-- fields error message -->
        <div v-if="$v.form.password.$error" class="my-0">
          <div v-if="!$v.form.password.required" class="error my-0 mb-3">
            Password is required
          </div>
          <div v-if="!$v.form.password.minLength" class="error my-0 mb-3">
            Password must be atleast 8 characters minimum
          </div>
        </div>
        <!-- fields error message -->
      </rm-input>
      <!-- <router-link :to="{ name: 'forgot-password' }">
        <small class="d-inline-block pb-5 text-primary">
          <b>Forgot Password?</b>
        </small>
      </router-link> -->

      <div class="text-center">
        <rm-base-button
          text="login"
          class="my-1 d-block d-lg-none"
          block
          small
          :disableButton="$v.$invalid || err || loading"
          :loading="loading"
        />

        <rm-base-button
          text="login"
          class="my-5 d-lg-block d-none"
          block
          large
          :disableButton="$v.$invalid || err || loading"
          :loading="loading"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loading: false,
      err: true,
      errorMsg: "",
    };
  },

  methods: {
    async login() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch("auth/login", this.form);
        if (res) {
        	// const { next } = this.$route.query;
        	// if (next != null && next != "")
        	// 	this.$router.replace({ path: next });
        	// else this.$router.replace({ name: "dashboard" });
          this.$router.replace({ name: "clients" });
        }
      } catch (err) {
        this.loading = false;
        this.form.password = "";
      } finally {
        this.loading = false;
      }
    },
    validateInputType() {
      let x = this.form.username.charAt(0);
      let isnum = /^\d+$/.test(x);
      if (this.form.username == "") {
        this.errorMsg = "Field is required";
        return;
      } else {
        if (isnum == true || x.includes("+")) {
          if (this.form.username.length < 7) {
            this.errorMsg = "Enter a valid phone number";
            return;
          }
        } else {
          let reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let me = reg.test(this.form.username);
          if (!me) {
            this.errorMsg = "Enter a valid email address";
            return;
          }
        }
      }
      this.err = false;
    },
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  h5 {
    color: #02123b;
    font-weight: 800;
    font-size: 1.6rem;
  }

  .btn-links {
    border-radius: 20rem;
    width: 70%;
    background-color: var(--primary--light);
    a {
      color: #02123b;
      text-decoration: none;
      margin-left: 10px;
    }
  }

  form {
    a {
      text-decoration: none;
    }
  }
}
</style>
